<template>
	<van-popup v-model="show" position="bottom" safe-area-inset-bottom class="pop">
		<div class="pop-title">
			<span class="ts">请选择推荐教练</span>
			<van-icon name="cross" class="pop-close" color="#fff" @click="show = false" />
		</div>
		<div class="pop-tuijian">
			<div class="search-box">
				<img alt=""
					src="https://chaolucoachwebstatic.chaolu.com.cn/static/img/group-invite-202304/search.png"
					class="search-icon" @click="search" />
				<form action="JavaScript：return true;" @submit.prevent="search">
					<input type="search" v-model.trim="searchName" class="ipt" placeholder="输入教练的名字" />
				</form>
			</div>
			<div class="tabbox" v-if="hasSearchData">
				<template v-if="teacherList.length">
					<div class="voucher-list">
						<div class="voucher-model" :class="{ active: acIndex === index && acType == 1 }"
							v-for="(item, index) in teacherList" @click="acType = 1, acIndex = acIndex == index ? undefined : index">
							<div class="v1">
								<img alt="" :src="item.headImg" />
							</div>
							<div class="v2 ellipsis">{{ item.teacherName }}</div>
						</div>
					</div>
				</template>
			</div>
			<div v-else>
				<img class="n1"
					src="https://chaolucoachwebstatic.chaolu.com.cn/static/img/group-invite-202304/none.png" alt />
				<div class="n2">暂无匹配的教练</div>
			</div>
			<template v-if="attentionList.length">
				<div class="gz">关注的教练</div>
				<div class="tabbox">
					<div class="voucher-list">
						<div class="voucher-model" :class="{ active: acIndex === index && acType == 2 }"
							v-for="(item, index) in attentionList" @click="acType = 2, acIndex = acIndex == index ? undefined : index">
							<div class="v1">
								<img alt="" :src="item.teacherImg" />
							</div>
							<div class="v2 ellipsis">{{ item.teacherName }}</div>
						</div>
					</div>
				</div>
			</template>
		</div>
		<div class="subline">
			<div class="sub1" @click="noTeacher">
				<div class="l1">直接邀请</div>
				<span class="l2">不推荐教练</span>
			</div>
			<div @click="sureTeacher" class="sub2" :class="{ gray: !acIndex && acIndex !== 0 }">推荐教练</div>
		</div>
	</van-popup>
</template>
<script>
import userMixin from '@/mixin/userMixin';
export default {
	mixins: [userMixin],
	data() {
		return {
			show: false,
			searchName: '',//搜索教练
			acIndex: undefined,
			acType: undefined,
			hasSearchData: true,
			teacherList: [],//搜索结果
			attentionList: [],//关注教练列表
		}
	},
	watch: {
		show(v) {
			if (v) {
				this.getAttention();
				this.searchName = '';
				this.acIndex = undefined;
				this.acType = undefined;
				this.teacherList = [];
				this.hasSearchData = true;
			}
		}
	},
	methods: {
		search() {
			this.$toast.loading({
				duration: 0,
				forbidClick: true,
				loadingType: 'spinner',
			})
			this.acIndex = undefined;
			this.acType = undefined;
			if (!this.searchName) {
				this.$toast('请输入教练名字');
				return;
			}
			this.$axios
				.post(this.baseURLApp + "/sche/oldBringNew/searchTeacher", {
					// cityId: this.cityId,
					page: 1,
					size: 1000,
					token: this.token,
					userId: this.userId,
					teacherName: this.searchName
				})
				.then((res) => {
					this.$toast.clear();
					this.hasSearchData = res.data.length > 0;
					this.teacherList = res.data;
				})
		},
		getAttention() {
			this.$axios
				.post(this.baseURLApp + "/userApp/user/attention/teacher/list/v3", {
					cityId: this.cityId,
					page: 1,
					size: 1000,
					token: this.token,
					userId: this.userId
				})
				.then((res) => {
					this.attentionList = res.data;
				})

		},
		sureTeacher() {
			const selectItem = this.acType == 1 ? this.teacherList[this.acIndex] : this.attentionList[this.acIndex];
			if (selectItem && !selectItem.teacherImg) {
				selectItem.teacherImg = selectItem.headImg;
			}
			this.$emit('setShareTeacher', selectItem);
			this.show = false;
		},
		noTeacher() {
			this.$emit('setShareTeacher', null);
			this.show = false;
		},
	},
	created() {

	}
}
</script>
<style lang="less" scoped>
.pop {
	border-top-left-radius: 16px;
	border-top-right-radius: 16px;

	.pop-title {
		color: #9E0D00;
		font-weight: bold;
		background: linear-gradient(173deg, #FFF9E8 0%, #FFFFFF 100%);
		font-size: 40px;
		text-align: center;
		height: 120px;
		position: relative;
		display: flex;
		align-items: center;
		justify-content: center;

		.ts {
			position: relative;
			transform-style: preserve-3d;
			display: inline-block;

			&::after {
				content: '';
				position: absolute;
				left: -6px;
				bottom: 0px;
				width: 314px;
				height: 18px;
				background: linear-gradient(90deg, #C3FE22 0%, rgba(255, 234, 177, 0) 100%);
				border-radius: 38px;
				transform: translateZ(-10px);
				z-index: -1;
			}
		}

		.pop-close {
			position: absolute;
			right: 32px;
			top: 38px;
			width: 44px;
			height: 44px;
			line-height: 44px;
			border-radius: 50%;
			background: rgba(39, 40, 49, 0.2);
			font-size: 28px;
			text-align: center;
		}
	}

}

.pop-tuijian {
	padding: 22px 0 0;
	height: 852px;
	overflow: auto;

	.search-box {
		border-radius: 8px;
		background: #F5F5F5;
		height: 72px;
		display: flex;
		align-items: center;
		padding: 0 16px;
		margin: 0 32px;

		input[type="search"] {
			-webkit-appearance: none;
		}

		input::-webkit-search-cancel-button {
			display: none;
		}

		.search-icon {
			width: 40px;
			height: 40px;
			margin-right: 10px;
		}

		.ipt {
			border: none;
			background: transparent;
			font-size: 30px;
			width: 100%;
			height: 100%;
		}
	}

	.tabbox {
		overflow-x: scroll;
		overflow-y: hidden;
		padding-left: 32px;
		position: relative;
		z-index: 1;

		&::-webkit-scrollbar {
			display: none;
		}

		.voucher-list {
			display: flex;
			float: left;
			margin-top: 32px;

			.voucher-model {
				width: 176px;
				height: 224px;
				margin-right: 28px;
				background: #F9F8FF;
				border-radius: 20px;
				color: #242831;
				border: 1px solid #E3E6FF;

				.v1 {
					width: 132px;
					height: 132px;
					margin: 22px;
					border-radius: 12px;
					overflow: hidden;
					background: #f5f5f5;

					img {
						width: 100%;
						height: 100%;
					}
				}

				.v2 {
					font-size: 26px;
					text-align: center;
					font-weight: bold;
				}

				&.active {
					background: linear-gradient(180deg, #ED7432 0%, #F0934C 100%);
					color: #fff;
					border: none;
				}
			}
		}
	}

	.n1 {
		width: 120px;
		height: 120px;
		display: block;
		margin: 70px auto 32px;
	}

	.n2 {
		font-size: 26px;
		color: #242831;
		text-align: center;
	}


	.gz {
		font-weight: bold;
		font-size: 28px;
		color: #242831;
		margin-left: 32px;
		margin-top: 64px;
	}
}

.subline {
	padding: 24px 32px;
	border-top: 1px solid #EEEEEE;
	display: flex;
	margin-top: 120px;

	.sub1 {
		width: 264px;
		height: 96px;
		border: 1px solid #242831;
		border-radius: 8px;
		text-align: center;
		color: #242831;
		margin-right: 28px;
		text-align: center;
		padding-top: 8px;
		box-sizing: border-box;

		.l1 {
			font-weight: bold;
			font-size: 32px;
		}

		.l2 {
			color: #666;
			font-size: 22px
		}
	}

	.sub2 {
		width: 394px;
		line-height: 96px;
		background: #FFDE00;
		border-radius: 8px;
		color: #242831;
		text-align: center;
		font-size: 32px;
		font-weight: bold;
	}
}

.gray {
	opacity: 0.5;
	pointer-events: none;
}
</style>
