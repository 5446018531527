<template>
	<div class="page-private-invite-202307">
		<headeBar left-arrow :color="header_color" :title="header_title" :background="header_background"
			:opacity="header_opacity" :placeholder="false" @click-left="newAppBack" />
		<div class="headbg">
			<div class="djs">
				<div class="time">{{ day }}</div>
			</div>
		</div>
		<div class="invite-box">
			<div class="sub1" @click="openShare(2)"></div>
			<div class="sub2" @click="openShare(1)">立即邀请<van-icon name="arrow" /></div>
		</div>
		<div class="gift-box">
			<div class="gift-content">
				<div class="gift-model" v-for="(i, index) in rewardMap2" :key="index">
					<div class="img-box">
						<img :src="i.rewardImg" />
						<p>{{ i.rewardName }}</p>
					</div>
					<div class="icon" :class="{ check: i.status == 'RECEIVED' || i.status == 'CAN_RECEIVE' }"></div>
					<p class="f22 tc">累计{{ i.threshold }}人{{ i.threshold >= 5 ? '及以上' : '' }}</p>
					<div class="gift-sub"
						:class="{ dis: i.status != 'CAN_RECEIVE' && i.status != 'RECEIVED', get: i.status == 'RECEIVED' }"
						@click="getRewrd(i, true)">
						{{ getName(i.status) }}
						<img alt
							src="https://chaolucoachwebstatic.chaolu.com.cn/static/img/group-invite-202304/arrow.png"
							class="getimg" v-if="i.status == 'RECEIVED'" />
					</div>
				</div>
				<div class="line"
					:style="{ background: 'linear-gradient(to right,#89D56E 0%,	#89D56E ' + lineArr[(inviteNum2 - 1) > 4 ? 4 : inviteNum2 - 1] + '%,#EEEEEE ' + lineArr[(inviteNum2 - 1) > 4 ? 4 : inviteNum2 - 1] + '%,#EEEEEE 100%)' }">
				</div>
				<div class="in-btn" @click="goPri()"></div>
			</div>
		</div>
		<div class="gift-head">
			<div class="to-reward-list" @click="torewardlist">我已成功邀请 {{ inviteNum }}人
				<div class="arrow-icon" />
			</div>
		</div>
		<div class="gift-list">
			<div class="level-model" :class="{ sm: item.length > 1 }" v-for="(item, key) in rewardMap" :key="key">
				<img :src="'https://img.chaolu.com.cn/ACT/private-invite-202307/invite' + key + '.png'" />
				<div v-if="item.length == 1" class="single-body">
					<div class="sb-imgbox">
						<img :src="item[0].rewardImg" />
					</div>
					<div class="sb-name">
						{{ item[0].rewardName }}
					</div>
					<div class="sb-sub" :class="{ dis: item[0].status != 'CAN_RECEIVE' && item[0].status != 'RECEIVED' }"
						@click="getRewrd(item[0])">
						{{ item[0].status == 'TASK_FULFILLED' ? '任务' : '' }}{{ getName(item[0].status) }}
						<img alt
							src="https://chaolucoachwebstatic.chaolu.com.cn/static/img/group-invite-202304/arrow.png"
							class="getimg" v-if="item[0].status == 'RECEIVED'" />
					</div>
				</div>
				<div class="level-body" v-else>
					<div class="reward-model" v-for="(citem, key2) in item" :key="key2">
						<div class="imgline">
							<div class="level-img">
								<img alt="" :src="citem.rewardImg" />
							</div>
						</div>
						<div class="level-name-box ellipsis">
							{{ citem.rewardName }}
						</div>
						<div class="level-sub" :class="{ dis: citem.status != 'CAN_RECEIVE' && citem.status != 'RECEIVED' }"
							@click="getRewrd(citem)">
							{{ getName(citem.status) }}
							<img alt
								src="https://chaolucoachwebstatic.chaolu.com.cn/static/img/group-invite-202304/arrow.png"
								class="getimg" v-if="citem.status == 'RECEIVED'" />
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="rule-box">
			<img src="https://img.chaolu.com.cn/ACT/private-invite-202307/rule.png" alt />
		</div>
		<van-popup v-model="showGet" position="center" class="pop-get">
			<div class="pop-get-body">
				<div class="pop-get-title">
					奖品领取确认
				</div>
				<div class="pop-get-tips">
					任务三选一，领取当前的奖品后，
				</div>
				<div class="pop-get-tips">
					将无法再领取 <span class="cr">其它任务</span> 的奖励
				</div>
				<div class="jp">
					<img :src="getObj.rewardImg" />
				</div>
				<div class="pop-get-sub" @click="sureReward"></div>
				<div class="noget" @click="showGet = false">我再想想</div>
			</div>
			<img class="popclose" src="https://img.chaolu.com.cn/ACT/private-invite-202307/popclose.png"
				@click="showGet = false" />
		</van-popup>
		<van-popup v-model="showCollectionRecords" position="bottom" safe-area-inset-bottom class="pop">
			<div class="pop-title">
				<span class="ts">领取记录</span>
				<van-icon name="cross" class="pop-close" color="#fff" @click="showCollectionRecords = false" />
			</div>
			<div class="collection-records">
				<div class="mode1" v-if="getObj.needVenue">已发放至您的优惠券账户，<br />请到选择的门店找教练兑换领取</div>
				<div class="jg"></div>
				<div class="records-box">
					<div class="records-model">
						<div class="records-img">
							<img :src="getObj.rewardImg" alt="" />
						</div>
						<div class="records-info">
							<div class="records-name">{{ getObj.rewardName }}</div>
							<div class="records-size" v-if="getObj.needVenue">兑换门店：{{ getObj.venueInfo.venueName }}</div>
						</div>
					</div>
				</div>
			</div>
		</van-popup>

		<van-overlay :show="isShareWx" :z-index="1500" @click="isShareWx = false">
			<div class="tip-box" @click.stop>
				<img src="https://chaolucoachwebstatic.chaolu.com.cn/static/img/anniversary2021/share-mini.png"
					class="tip-img" />
			</div>
		</van-overlay>
		<ChooseTeacher @setShareTeacher="setShareTeacher" ref="refChooseTeacher" />
		<InviteList ref="refInviteList" />
		<common-share :value="showShare" :shareParams="shareParams" @close="showShare = false"
			@share-success="showShare = false" :shareItem="['posterfriend', 'postermessage', 'save']"
			:miniSharePoster="appTypeStr === 'mini'">
			<template slot="createDom">
				<div class="canvascss" v-if="shareTeacherObj">
					<img src="https://img.chaolu.com.cn/ACT/private-invite-202307/sharebg-teacher.png" class="canvascss_bg" />
					<div class="tl">
						<img :src="shareTeacherObj.teacherImg" class="uhead" />
						<div class="pub_onefull">
							<div class="uname">{{ shareTeacherObj.teacherName }}</div>
						</div>
					</div>
					<div class="ul">
						<img class="ucode" :src="userHeadImg" />
						<div class="uinfo ellipsis">
							<div class="u1 ellipsis">{{ nickName }}</div>
						</div>
						<div class="wai">
							<img class="nei" :src="userPoster" />
						</div>
					</div>
				</div>
				<div class="canvascss2" v-else>
					<img src="https://img.chaolu.com.cn/ACT/private-invite-202307/sharebg.png" class="canvascss_bg" />
					<div class="ul">
						<img class="ucode" :src="userHeadImg" />
						<div class="uinfo ellipsis">
							<div class="u1 ellipsis">{{ nickName }}</div>
						</div>
						<div class="wai">
							<img class="nei" :src="userPoster" />
						</div>
					</div>
				</div>
			</template>
		</common-share>
	</div>
</template>
<script>
import { newAppBack, initBack, saveCreateImg, webAppShare } from '@/lib/appMethod';
import headeBar from '@/components/app/headBar';
import commonShare from '@/components/commonShare';
import userMixin from '@/mixin/userMixin';
import wx from 'weixin-js-sdk';
import headerMixin from '@/mixin/headerMixin';
import ChooseTeacher from './components/chooseTeacher.vue';
import InviteList from './components/inviteList.vue';
const pageUrl = `${window.location.origin}/#/superdeer-activity/private-invite-202307/accept`
const shareUrl = `pages/webView/index?webUrl=${pageUrl}`;
const inviteRewardsType = "PER_SUCCEEDED_IN_RECRUITING_REWARD_FOR_INVITER";
const inviteRewardsType2 = "PER_SUCCEEDED_IN_SUPERVISE_REWARD_FOR_INVITER";
const inviteType = "PER_INVITE";

export default {
	components: {
		headeBar,
		commonShare,
		ChooseTeacher,
		InviteList
	},
	mixins: [userMixin, headerMixin],
	data() {
		return {
			showCollectionRecords: false,//显示领取记录弹窗
			userHeadImg: '',
			nickName: '',
			userPoster: '',
			isShareWx: false,
			rewardMap: {},//奖品等级
			rewardMap2: {},
			finished: false,
			pageNum: 1,
			pageSize: 10,
			showShare: false, // 是否显示分享弹窗
			showGet: false,//领取奖品弹窗
			shareParams: {
				title: '找私教上超鹿，7月新人加赠自助周卡！',
				content: '找私教上超鹿，7月新人加赠自助周卡！',
				multiple: '1',
				userName: 'gh_7f5b57b6a76e',
				miniProgramType: window.location.origin.indexOf('web.chaolu.com') > -1 ? '0' : '2',
				url: 'url',
				miniImage: 'https://img.chaolu.com.cn/ACT/private-invite-202307/mini2.png',
				path: shareUrl,
			},
			inviteNum: 0,
			inviteNum2: 0,
			day: 0,
			getObj: {},//确认奖品展示
			shareTeacherObj: null,
			lineArr: [12, 32, 52, 69, 100]
		}
	},
	methods: {
		saveCreateImg,
		newAppBack,
		onlyItem(item) {
			return item.some(d => {
				return d.status == 'CAN_RECEIVE'
			})
		},
		sureReward() {
			this.showGet = false;
			this.$router.push('/superdeer-activity/private-invite-202307/receive?rewardId=' + this.getObj.rewardId)
		},
		getRewrd(item, type) {
			if (item.status == 'CAN_RECEIVE') {
				if (type) {
					if (item.needVenue) {
						this.$router.push('/superdeer-activity/private-invite-202307/receive?type=1&rewardId=' + item.rewardId)
					} else {
						const ps = {
							inviteRewardsType: inviteRewardsType2,
							inviteType,
							rewardId: item.rewardId,
							userId: this.userId,
							sizeInfo: { shirtSize: '', shoeSize: '' },
							venueInfo: {
								venueId: '',
								venueName: ''
							}
						}
						this.$axios
							.post(this.baseURLApp + "/newPer/invitation/manualReceive", ps)
							.then((res) => {
								this.$toast.clear();
								this.$toast('领取成功');
								this.getRewardList2();
							}).catch(() => {
								this.$toast.clear()
							});
					}
					return
				}
				this.getObj = { venueInfo: {}, ...item };
				this.showGet = true;
			} else if (item.status == 'RECEIVED') {
				this.$toast.loading({
					duration: 0,
					forbidClick: true,
					loadingType: 'spinner',
				})
				this.$axios
					.post(this.baseURLApp + "/schedule/invitation/getRewardDetail", {
						inviteRewardsType: type ? inviteRewardsType2 : inviteRewardsType,
						inviteType,
						rewardId: item.rewardId,
						userId: this.userId
					})
					.then((res) => {
						this.getObj = res.data;
						this.showCollectionRecords = true;
						this.$toast.clear();
					}).catch(() => {
						this.$toast.clear();
					})
			}
		},
		getName(v) {
			const json = {
				'RECEIVED': '已领取',
				'RECEIVED_OTHER': '已领取其它奖品',
				'CAN_RECEIVE': '领取',
				'TASK_FULFILLED': '未达成'
			}
			return json[v];
		},
		torewardlist() {
			this.$refs.refInviteList.open();
		},
		setShareTeacher(tObj) {
			if (tObj) {
				this.shareParams.path = `${shareUrl}&inviteUid=${this.userId}&location=1&cityId=1&teacherId=${tObj.teacherId}`;
				this.shareTeacherObj = tObj;
			}
			else {
				this.shareParams.path = `${shareUrl}&inviteUid=${this.userId}&location=1&cityId=1`;
				this.shareTeacherObj = null;
			}
			this.toShare();
		},
		async toShare() {
			if (this.shareType == 1) {
				this.wxInvite();
			} else if (this.shareType == 2) {
				await this.sharePoster();
				this.showShare = true;
			}
		},
		sharePoster() {
			const ps = {
				source: 'private-invite-202307',
				webUrl: pageUrl,
				cityId: 1,
				userId: 1,
				inviteUid: this.userId,
			}
			if (this.shareTeacherObj) {
				ps.teacherId = this.shareTeacherObj?.teacherId
			}
			return this.$axios.post(`${this.baseURLApp}/qrCode/createOrdinaryCode`, {
				hasWx: true,
				userId: this.userId,
				token: this.token,
				width: 240,
				height: 240,
				webUrl: 'https://web.chaolu.com.cn/#/ordinary_004001',
				scene: JSON.stringify(ps)
			}).then((res) => {
				this.userPoster = 'data:image/png;base64,' + res.data.qrCode
				this.userHeadImg = res.data.headImg
				this.nickName = res.data.nickName
			})
		},
		wxInvite() {
			if (!this.userId) {
				this.$toast('请稍后再试');
				return;
			}

			if (this.appTypeStr === 'mini') {
				wx.miniProgram.postMessage({
					data: {
						type: 'share',
						shareImage: this.shareParams.miniImage,
						shareTitle: this.shareParams.title,
						shareUrl: this.shareParams.path,
					},
				})
				this.isShareWx = true
			} else {
				let params = {
					scene: 0,
					...this.shareParams
				}
				let miniParams = JSON.parse(JSON.stringify(params))
				miniParams.image = miniParams.miniImage
				webAppShare('3', miniParams)
			}
		},
		openShare(type) {
			this.shareType = type;//1微信分享，2海报分享
			this.$refs.refChooseTeacher.show = true;
		},
		getRewardList() {
			this.$axios
				.post(this.baseURLApp + "/schedule/invitation/manualReceiveDetail", {
					inviteRewardsType,
					inviteType,
					userId: this.userId
				})
				.then((res) => {
					this.getRewardList2();
					this.inviteNum = res.data.inviteNum;
					this.day = res.data.activityDay;
					const map = {}
					const d = res.data.rewardMap
					for (let i in d) {
						for (let j = 0; j < d[i].length; j++) {
							const model = d[i][j];
							if (!map[model.threshold]) {
								map[model.threshold] = [model]
							} else {
								map[model.threshold].push(model)
							}
						}
					}
					this.rewardMap = map;
				}).catch(() => {
					this.$dialog.alert({
						title: '提示',
						message: '暂无活动进行中',
						confirmButtonText: '我知道了',
						confirmButtonColor: '',
						overlayStyle: { backgroundColor: 'rgba(0,0,0,1)' }
					}).then(() => {
						this.newAppBack();
					});
				})
		},
		getRewardList2() {
			this.$axios
				.post(this.baseURLApp + "/newPer/invitation/manualReceiveDetail", {
					inviteRewardsType: inviteRewardsType2,
					inviteType,
					userId: this.userId
				})
				.then((res) => {

					this.inviteNum2 = res.data.inviteNum;
					const map = {}
					const d = res.data.rewardMap
					for (let i in d) {
						map[i] = d[i][0]
					}
					this.rewardMap2 = map;

				}).catch(() => {
				})
		},
		goPri() {
			if (!this.userId) {
				this.$toast('请稍后再试');
				return;
			}
			let data = {
				title: '找私教上超鹿，7月新人加赠自助周卡！',
				multiple: '1',
				userName: 'gh_7f5b57b6a76e',
				miniProgramType: window.location.origin.indexOf('web.chaolu.com') > -1 ? '0' : '2',
				url: 'url',
				miniImage: 'https://img.chaolu.com.cn/ACT/private-invite-202307/mini2.png',
				path: '/pages/priCoach/index?venueId=' + this.vanueId
			}

			if (this.appTypeStr === 'mini') {
				wx.miniProgram.postMessage({
					data: {
						type: 'share',
						shareImage: data.miniImage,
						shareTitle: data.title,
						shareUrl: data.path,
					},
				})
				this.isShareWx = true
			} else {
				let params = {
					scene: 0,
					...data
				}
				let miniParams = JSON.parse(JSON.stringify(params))
				miniParams.image = miniParams.miniImage
				webAppShare('3', miniParams)
			}
		}

	},
	mounted() {
		this.bindHeaderScroll(this.$el, 200, (scrollTop, morehalf) => {

		});
	},
	async created() {
		initBack();
		await this.$getAllInfo(['userId', 'cityId', 'venueId']);
		this.getRewardList();
	}
}
</script>
<style lang="less" scoped>
.page-private-invite-202307 {
	background: #C1EB93;
	position: absolute;
	height: 100vh;
	top: 0;
	// bottom: 0;
	left: 0;
	right: 0;
	overflow: auto;

	div {
		box-sizing: border-box;
	}


	img {
		vertical-align: top;
		width: 100%;
	}

	.gray {
		opacity: 0.5;
		pointer-events: none;
	}

	.headbg {
		height: 784px;
		background: url(https://img.chaolu.com.cn/ACT/private-invite-202307/headbg.png);
		background-size: 100% 100%;
		position: relative;
	}

	.djs {
		position: absolute;
		bottom: 0;
		width: 536px;
		left: 50%;
		transform: translateX(-50%);
		height: 64px;
		background: url('https://img.chaolu.com.cn/ACT/private-invite-202307/timebg.png');
		background-size: cover;
		z-index: 2;

		.time {
			width: 84px;
			height: 42px;
			line-height: 44px;
			text-align: center;
			margin: 10px 0 0 352px;
			font-size: 34px;
			color: #000;
			font-weight: bold;
		}
	}

	.pop-get {
		background: transparent;

		.pop-get-body {
			border-radius: 32px;
			width: 630px;
			height: 836px;
			background-image: url(https://img.chaolu.com.cn/ACT/private-invite-202307/getbg.png);
			background-size: 100% 100%;
			text-align: center;
			padding-top: 80px;

			.pop-get-title {
				color: #9E0D00;
				font-weight: bold;
				font-size: 48px;
				line-height: 1;
				margin-bottom: 32px;
			}

			.pop-get-tips {
				color: #866153;
				font-size: 28px;
				line-height: 36px;
				font-weight: bold;
				line-height: 42px;

				.cr {
					color: #CF1436;
				}
			}

			.jp {
				width: 288px;
				height: 288px;
				margin: 36px auto;
			}

			.pop-get-sub {
				width: 382px;
				height: 112px;
				background: url(https://img.chaolu.com.cn/ACT/private-invite-202307/getsub.png);
				background-size: 100% 100%;
				margin: 0 auto 36px;
			}

			.noget {
				font-size: 32px;
				color: #622009;
			}
		}

		.popclose {
			width: 60px;
			height: 60px;
			display: block;
			margin: 64px auto 0;
		}
	}

	.invite-box {
		margin: 24px 28px 0;
		height: 700px;
		background: url(https://img.chaolu.com.cn/ACT/private-invite-202307/voucherbg.png?1);
		background-size: 100% 100%;
		position: relative;
		padding-top: 492px;

		.sub1 {
			margin: 0 auto;
			width: 586px;
			height: 108px;
			background: url(https://img.chaolu.com.cn/ACT/private-invite-202307/share-button.png);
			background-size: cover;
		}

		.sub2 {
			margin-top: 22px;
			font-size: 32px;
			color: #fff;
			text-align: center;
			font-weight: bold;
		}
	}

	.gift-box {
		width: 694px;
		height: 664px;
		background: url(https://img.chaolu.com.cn/ACT/private-invite-202307/gift-bg.png);
		background-size: 100% 100%;
		margin: 32px 28px 88px;
		padding-top: 214px;
		box-sizing: border-box;

		.gift-content {
			display: flex;
			justify-content: space-around;
			position: relative;

			.gift-model {
				z-index: 9;
				width: 158px;
				text-align: center;

				.img-box {
					width: 148px;
					height: 184px;
					background-color: #FDF4ED;
					border-radius: 16px;
					border: 2px solid #FFDDE2;
					text-align: center;
					font-size: 20px;
					margin: 0 auto;
					img {
						width: 116px;
						height: 116px;
					}

					p {
						padding: 2px 20px 0px;
						font-weight: 500;
					}
				}

				.icon {
					width: 30px;
					height: 30px;
					border-radius: 30px;
					margin: 20px auto 22px;
					background-color: #EEEEEE;

					&.check {
						background-image: url(https://img.chaolu.com.cn/ACT/private-invite-202307/check.png);
						background-size: 100% 100%;
					}
				}

				.gift-sub {
					width: 148px;
					height: 56px;
					background: url(https://img.chaolu.com.cn/ACT/private-invite-202307/longsub0.png);
					background-size: 100% 100%;
					margin: 20px auto 0px;
					text-align: center;
					color: #fff;
					font-size: 24px;
					display: flex;
					align-items: center;
					justify-content: center;
					font-weight: bold;

					.getimg {
						width: 28px;
						height: 28px;
						margin-left: 6px;
					}

					&.dis {
						background-image: none;
						color: #FF155B;
						background-color: #FFFFFF;
						border: 2px solid #FF9AAD;
						border-radius: 50px;
					}

					&.get {
						background: url(https://img.chaolu.com.cn/ACT/private-invite-202307/longsub6.png);
						background-size: 100% 100%;
					}
				}
			}

			.line {
				width: 622px;
				height: 10px;
				background-color: #EEEEEE;
				border-radius: 20px;
				position: absolute;
				bottom: 138px;
			}

			.in-btn {
				background: url(https://img.chaolu.com.cn/ACT/private-invite-202307/in-btn.png);
				width: 504px;
				height: 94px;
				background-size: 100% 100%;
				position: absolute;
				bottom: -130px;
			}
		}

	}

	.gift-head {
		height: 146px;
		background: url(https://img.chaolu.com.cn/ACT/private-invite-202307/gifthead.png);
		background-size: cover;
		position: relative;
		margin: 32px 28px 0;

		.to-reward-list {
			display: flex;
			position: absolute;
			right: 30px;
			top: 32px;
			color: #fff;
			font-size: 26px;
			font-weight: bold;
			align-items: center;

			.arrow-icon {
				width: 32px;
				height: 32px;
				background: url(https://chaolucoachwebstatic.chaolu.com.cn/static/img/group-invite-202304/arrow.png);
				background-size: cover;
				margin-left: 16px;
			}
		}
	}

	.gift-list {
		background: #FFFDFA;
		margin: 0 28px;
		padding: 0 32px 10px;
		border-bottom-left-radius: 32px;
		border-bottom-right-radius: 32px;

		.level-model {
			position: relative;
			margin-bottom: 74px;
			// padding: 90px 16px 0;

			&.sm {
				margin-bottom: 32px;
			}

			.single-body {
				position: absolute;
				top: 90px;
				left: 0;
				right: 0;
				bottom: 0;

				.sb-imgbox {
					width: 236px;
					height: 236px;
					margin: 0 auto;
				}

				.sb-name {
					font-weight: bold;
					font-size: 30px;
					color: #000;
					text-align: center;
					line-height: 1.4;
					margin: 4px auto 16px;
					width: 494px;
				}

				.sb-sub {
					position: absolute;
					bottom: -26px;
					left: 50%;
					transform: translateX(-50%);
					width: 372px;
					height: 86px;
					background: url(https://img.chaolu.com.cn/ACT/private-invite-202307/longsub.png);
					background-size: 100% 100%;
					margin: 0 auto;
					text-align: center;
					color: #fff;
					font-size: 32px;
					display: flex;
					align-items: center;
					justify-content: center;
					font-weight: bold;

					.getimg {
						width: 28px;
						height: 28px;
						margin-left: 16px;
					}

					&.dis {
						background-image: url(https://img.chaolu.com.cn/ACT/private-invite-202307/longsub2.png);
						color: #6C727A;
					}
				}
			}

			.level-body {
				position: absolute;
				top: 100px;
				bottom: 0;
				left: 0;
				right: 0;
				display: flex;
				justify-content: space-between;
				padding: 0 16px;

				.reward-model {
					width: 290px;
					height: 318px;
					border-radius: 10px;
					background: #FCF0E2;
					text-align: center;
					border-radius: 12px;

					.imgline {
						height: 204px;
						background: #fff;
						display: flex;
						justify-content: center;
						align-items: center;
					}

					.level-img {
						width: 184px;
						height: 184px;

						img {
							height: 100%;
							width: 100%;
						}
					}

					.level-name-box {
						font-size: 26px;
						color: #000;
						font-weight: bold;
						text-align: center;
						margin-top: 26px;
						line-height: 1;
						margin-bottom: 20px;
					}

					.level-sub {
						width: 250px;
						height: 72px;
						background: url(https://img.chaolu.com.cn/ACT/private-invite-202307/subbg.png);
						background-size: 100% 100%;
						margin: 0 auto;
						text-align: center;
						color: #fff;
						font-size: 28px;
						display: flex;
						align-items: center;
						justify-content: center;
						font-weight: bold;

						.getimg {
							width: 28px;
							height: 28px;
							margin-left: 16px;
						}

						&.dis {
							background-image: url(https://img.chaolu.com.cn/ACT/private-invite-202307/subbg2.png);
							color: #6C727A;
						}
					}
				}
			}
		}
	}

	.rule-box {
		margin: 32px 28px;
		padding-bottom: 100px;
	}

	.tip-box {
		position: relative;

		.tip-img {
			position: absolute;
			top: 7px;
			right: 130px;
			width: 300px;
		}
	}

	.pop {
		border-top-left-radius: 16px;
		border-top-right-radius: 16px;

		.pop-title {
			color: #9E0D00;
			font-weight: bold;
			background: linear-gradient(173deg, #FFF9E8 0%, #FFFFFF 100%);
			font-size: 40px;
			text-align: center;
			height: 120px;
			position: relative;
			display: flex;
			align-items: center;
			justify-content: center;

			.ts {
				position: relative;
				transform-style: preserve-3d;
				display: inline-block;

				&::after {
					content: '';
					position: absolute;
					left: -6px;
					bottom: 0px;
					width: 154px;
					height: 18px;
					background: linear-gradient(90deg, #C3FE22 0%, rgba(255, 234, 177, 0) 100%);
					border-radius: 38px;
					transform: translateZ(-10px);
					z-index: -1;
				}
			}

			.pop-close {
				position: absolute;
				right: 32px;
				top: 38px;
				width: 44px;
				height: 44px;
				line-height: 44px;
				border-radius: 50%;
				background: rgba(39, 40, 49, 0.2);
				font-size: 28px;
				text-align: center;
			}
		}
	}

	.collection-records {
		.mode1 {
			font-size: 28px;
			color: #5A0A00;
			text-align: center;
			padding: 32px 0;
			line-height: 44px;
		}

		.mode2 {
			display: flex;
			align-items: center;
			padding: 40px 32px 56px;
			position: relative;

			&::after {
				content: '';
				height: 4px;
				background-image: url(https://chaolucoachwebstatic.chaolu.com.cn/static/img/group-invite-202304/border.png);
				background-size: cover;
				position: absolute;
				bottom: 0;
				left: 0;
				right: 0;
			}

			.maplogo {
				width: 40px;
				height: 40px;
				margin-right: 20px;
			}

			.line1 {
				font-size: 24px;
				color: #666;
			}

			.line2 {
				color: #242831;
				font-size: 30px;
				font-weight: bold;
				margin: 20px 0;
			}

			.line3 {
				color: #242831;
				font-size: 26px;

				.phone {
					color: #666;
				}
			}
		}

		.jg {
			height: 16px;
			background: #F5F5F5;
		}

		.records-box {
			min-height: 520px;
		}

		.records-model {
			display: flex;
			padding: 48px 32px 0;

			.records-img {
				width: 192px;
				height: 192px;
				margin-right: 32px;
			}

			.records-info {
				display: flex;
				flex-direction: column;
				justify-content: center;

				.records-name {
					font-size: 28px;
					font-weight: bold;
					color: #242831;
				}

				.records-size {
					font-size: 24px;
					color: #242831;
					line-height: 1;
					margin-top: 20px;
				}
			}



			.records-tips {
				font-size: 22px;
				color: #666;
				line-height: 34px;
			}
		}
	}

	@bei: 1px;

	.canvascss {
		position: fixed;
		bottom: -200%;
		// top: 0;
		width: @bei*654;
		height: @bei*1204;
		margin: 0 auto;
		box-sizing: border-box;

		.canvascss_bg {
			width: 100%;
			height: 100%;
		}

		.tl {
			position: absolute;
			left: @bei*40px;
			top: @bei*24px;
			display: flex;
			align-items: center;
			color: #fff;
			font-size: @bei*28px;

			.uhead {
				width: @bei*88;
				height: @bei*88;
				border-radius: 50%;
				margin-right: @bei*24;
			}

			.uname {
				font-weight: bold;
			}
		}

		.ul {
			position: absolute;
			left: @bei*32px;
			bottom: @bei*10px;
			right: @bei*32;
			display: flex;
			color: #fff;

			.ucode {
				z-index: 1;
				width: @bei*100;
				height: @bei*100;
				border-radius: 50%;
				overflow: hidden;
				margin-right: @bei*30px;
			}

			.uinfo {
				flex: 1;
				margin-right: @bei*20;

				.u1 {
					font-size: @bei*28px;
					font-weight: bold;
					color: #242831;
					margin-bottom: @bei*10px;
				}

				.u2 {
					color: #242831;
					font-size: @bei*20px;
				}
			}

			.wai {
				background: linear-gradient(to bottom, #E10DEE 0%, #6E02CE 100%);
				border-radius: @bei*14px;
				width: @bei*124;
				height: @bei*124;
				padding: @bei*10px;
				position: relative;
				top: @bei*-20px;

				.nei {
					width: 100%;
					height: 100%;
				}
			}
		}
	}

	.canvascss2 {
		position: fixed;
		bottom: -200%;
		// top: 0;
		width: @bei*654;
		height: @bei*1102;
		margin: 0 auto;
		box-sizing: border-box;

		.canvascss_bg {
			width: 100%;
			height: 100%;
		}

		.ul {
			position: absolute;
			left: @bei*32px;
			bottom: @bei*10px;
			right: @bei*32;
			display: flex;
			color: #fff;

			.ucode {
				z-index: 1;
				width: @bei*100;
				height: @bei*100;
				border-radius: 50%;
				overflow: hidden;
				margin-right: @bei*30px;
			}

			.uinfo {
				flex: 1;
				margin-right: @bei*20;

				.u1 {
					font-size: @bei*28px;
					font-weight: bold;
					color: #242831;
					margin-bottom: @bei*10px;
				}

				.u2 {
					color: #242831;
					font-size: @bei*20px;
				}
			}

			.wai {
				background: linear-gradient(to bottom, #E10DEE 0%, #6E02CE 100%);
				border-radius: @bei*14px;
				width: @bei*124;
				height: @bei*124;
				padding: @bei*10px;
				position: relative;
				top: @bei*-20px;

				.nei {
					width: 100%;
					height: 100%;
				}
			}
		}
	}

	/deep/.canvas-box {
		padding-left: 40px !important;
		padding-right: 40px !important;
		box-sizing: border-box;

		.create-img {
			width: 100% !important;
		}
	}
}
</style>
