<template>
	<van-popup v-model="show" position="bottom" safe-area-inset-bottom class="pop-invite-list">
		<div class="pop-title">
			<span class="ts">邀请记录</span>
			<van-icon name="cross" class="pop-close" color="#fff" @click="show = false" />
		</div>
		<div class="body">
			<template v-if="hasData">
				<div class="htitle">活动时间：</div>
				<div class="head">
					<div class="psl">2023年7月1日 至 2023年7月31日</div>
					<div class="plr">成功邀请<div class="number">{{ number }}</div>人</div>
				</div>
				<div class="sc">
					<div class="list" v-for="citem in list">
						<div class="flexline">
							<div class="img">
								<img alt :src="citem.headImg" />
							</div>
							<div class="pub_onefull ct">
								<div class="username ellipsis">{{ citem.nickName }}</div>
								<div class="time">邀请时间：{{ citem.createTime }}</div>
								<div class="time" v-if="citem.inviteStatus == 2">上课时间：{{ citem.recordTime }}</div>
							</div>
							<div class="right" :class="{ success: citem.inviteStatus == 2 }">
								<div class="end" v-if="citem.inviteStatus == 1">
									<template v-if="citem.remainingDays == 0">
										今日23:59邀请失效
									</template>
									<template v-else>
										距邀请失效仅剩 <span>{{ citem.remainingDays }}</span> 天
									</template>
								</div>
								<div class="sub" :class="{ sub1: citem.inviteStatus == 1, sub2: citem.inviteStatus == 3 }"
									v-if="citem.inviteStatus == 1 || citem.inviteStatus == 3">{{
										getName(citem.inviteStatus) }}</div>
								<div v-if="citem.inviteStatus == 2">邀请成功</div>
							</div>
						</div>
						<div class="info" v-if="citem.failMsg">
							<img alt
								src="https://chaolucoachwebstatic.chaolu.com.cn/static/img/group-invite-202304/info.png"
								class="infologo" />
							{{ citem.failMsg }}
						</div>
					</div>
				</div>
			</template>
			<div v-else class="nodata">
				<div class="noimg">
					<img alt
						src="https://chaolucoachwebstatic.chaolu.com.cn/static/img/group-invite-202304/none2.png" />
				</div>
				<div class="notips">暂无邀请记录</div>
			</div>
		</div>
	</van-popup>
</template>
<script>
import userMixin from '@/mixin/userMixin';
export default {
	mixins: [userMixin],
	data() {
		return {
			show: false,
			list: [],
			hasData: true,
			number: 0
		}
	},
	methods: {
		getName(v) {
			const json = {
				1: '待上课',
				2: '邀请成功',
				3: '已失效',
				4: '邀请失败'
			}
			return json[v];
		},
		getData() {
			this.$axios
				.post(this.baseURLApp + "/newPer/invitation/invitationRecord", {
					hasWx: true,
					userId: this.userId,
					pageNum: 1,
					pageSize: 1000
				})
				.then((res) => {
					this.hasData = res.data.records.length > 0;
					this.list = res.data.records;
				})
		},
		getNum() {
			this.$axios
				.post(this.baseURLApp + "/newPer/invitation/userNumber", {
					hasWx: true,
					userId: this.userId
				}).then((res) => {
					this.number = res.data.invitationSucceededNumber;
				})
		},
		open() {
			this.getData();
			this.getNum();
			this.show = true;
		}
	},
	created() {
		// this.open();
	}
}
</script>
<style lang="less" scoped>
.pop-invite-list {
	border-top-left-radius: 16px;
	border-top-right-radius: 16px;

	.pop-title {
		color: #9E0D00;
		font-weight: bold;
		background: linear-gradient(173deg, #FFF9E8 0%, #FFFFFF 100%);
		font-size: 40px;
		text-align: center;
		height: 120px;
		position: relative;
		display: flex;
		align-items: center;
		justify-content: center;

		.ts {
			position: relative;
			transform-style: preserve-3d;
			display: inline-block;

			&::after {
				content: '';
				position: absolute;
				left: -6px;
				bottom: 0px;
				width: 154px;
				height: 18px;
				background: linear-gradient(90deg, #C3FE22 0%, rgba(255, 234, 177, 0) 100%);
				border-radius: 38px;
				transform: translateZ(-10px);
				z-index: -1;
			}
		}

		.pop-close {
			position: absolute;
			right: 32px;
			top: 38px;
			width: 44px;
			height: 44px;
			line-height: 44px;
			border-radius: 50%;
			background: rgba(39, 40, 49, 0.2);
			font-size: 28px;
			text-align: center;
		}
	}

	img {
		width: 100%;
		vertical-align: top;
	}

	.body {
		overflow: hidden;
		height: 800px;
		padding: 0 32px;
		position: relative;
		display: flex;
		flex-direction: column;
	}

	.nodata {
		.noimg {
			width: 184px;
			height: 120px;
			margin: 220px auto 0;
		}

		.notips {
			margin-top: 26px;
			font-size: 26px;
			color: #333;
			text-align: center;
		}
	}


	.htitle {
		font-size: 24px;
		color: #666;
		margin-bottom: 8px;
	}

	.head {
		display: flex;
		justify-content: space-between;
		font-size: 26px;
		color: #242831;
		font-weight: bold;
		margin-bottom: 20px;

		.number {
			border-radius: 4px;
			background: #EC6A2C;
			height: 32px;
			line-height: 34px;
			min-width: 12px;
			padding: 0 10px;
			display: inline-block;
			color: #fff;
			text-align: center;
			margin: 0 8px;
			font-size: 24px;
			position: relative;
			// top: -2px;
		}
	}

	.sc {
		flex: 1;
		overflow: auto;
	}

	.list {
		background: #FFFAF8;
		border: 1px solid #FAE4D5;
		margin-bottom: 24px;
		border-radius: 16px;
		padding: 28px;

		.flexline {
			display: flex;
		}

		.img {
			width: 80px;
			height: 80px;
			border-radius: 50%;
			overflow: hidden;
			margin-right: 20px;

			img {
				width: 100%;
				height: 100%;
			}
		}

		.ct {
			overflow: hidden;

			.username {
				flex: 1;
				color: #242831;
				font-size: 28px;
				font-weight: bold;
				margin-bottom: 12px;
			}

			.time {
				color: #6C727A;
				font-size: 22px;
				margin-top: 10px;
			}
		}

		.right {
			flex-shrink: 0;

			.end {
				font-size: 22px;
				color: #6C727A;
				margin-bottom: 10px;

				span {
					color: #FF6E00;
				}
			}


			.sub {
				width: 104px;
				height: 40px;
				border-radius: 4px;
				font-size: 24px;
				text-align: center;
				color: #fff;
				line-height: 42px;
				float: right;
				border-radius: 4px;
			}

			.sub1 {
				background: linear-gradient(90deg, #799FF9 0%, #5F83F3 100%);

			}

			.sub2 {
				background: #9AA1A9;
			}
		}

		.success {
			display: flex;
			align-items: center;
			font-weight: bold;
			color: #FF6E00;
			font-size: 30px;
			align-self: center;
		}

		.info {
			color: #432F17;
			font-size: 22px;
			margin-top: 28px;
			display: flex;
			align-items: center;

			.infologo {
				width: 24px;
				height: 24px;
				margin-right: 8px;
			}
		}
	}

}</style>
